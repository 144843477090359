<template>
  <v-container class="pa-0">
    <v-container>
      <v-row justify="end" align="center">
        <v-col>
          <span class="text-h3">{{ subtitle }}</span>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" outlined @click="add(groupId)">
            <v-icon class="mr-2">mdi-plus</v-icon>
            <span class="text-h5">追加</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="tmpItems"
        hide-default-header
        hide-default-footer
        :items-per-page="-1"
        class="mt-4 elevation-1"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th v-for="(h, index) in headers" :key="index" :class="h.class">
                <span class="primary--text text-h5">{{ h.text }}</span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:body="props">
          <draggable
            :list="props.items"
            tag="tbody"
            :options="{ animation: 300, handle: '.handle' }"
            @update="onmove(props.items)"
            @end="onend(props.items)"
            :sort="sortable"
          >
            <tr
              v-for="(element, index) in props.items"
              :key="index"
              :class="createRowStyle(element)"
            >
              <td class="text-left handle">
                <v-tooltip right v-if="sortable">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on"> mdi-arrow-all </v-icon>
                  </template>
                  <span>ドラッグで上下に動かせます</span>
                </v-tooltip>
              </td>
              <td>{{ index + 1 }}</td>
              <td class="text-left pa-2">
                <v-list-item-avatar size="60" tile>
                  <ImgWrapper :src="createImageUrl(element)" />
                </v-list-item-avatar>
              </td>
              <td>{{ element.name }}</td>
              <td class="text-right">
                <span class="pr-4">{{ element.amountBaseEx }}</span>
              </td>
              <td class="text-left">税込</td>
              <td class="text-left">
                <span
                  :class="createDiscountStatusStyle(element.discountStatus)"
                  >{{ createDiscountStatusText(element.discountStatus) }}</span
                >
              </td>
              <td class="text-left">
                <span
                  :class="createProvideStatusStyle(element.provideStatus)"
                  >{{ createProvideStatusText(element.provideStatus) }}</span
                >
              </td>
              <td class="text-left">
                <span v-if="element.publish" class="red--text">公開中</span>
                <span v-else>非公開</span>
              </td>
              <td>
                <v-icon @click="edit(element)"> mdi-pencil </v-icon>
              </td>
              <td v-if="deletable">
                <v-icon @click="del(element.id)">mdi-close</v-icon>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </v-container>

    <!-- 部品 -->
    <v-container>
      <v-dialog v-model="delInfo.show" max-width="300" persistent>
        <v-card>
          <v-card-title> 削除してよろしいですか? </v-card-title>
          <v-card-text class="pb-6 pt-12 text-center">
            <v-btn class="mr-3" text @click="delInfo.show = false">
              キャンセル
            </v-btn>
            <v-btn color="primary" text @click="confirmDel(delInfo)">
              削除する
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
const _cloneDeep = require("lodash.clonedeep");
import { DISCOUNT_STATUS, PROVIDE_STATUS } from "../../../../constants";

export default {
  name: "SortableList",
  components: {
    draggable,
    ImgWrapper: () => import("../img/ImgWrapper.vue"),
  },
  props: {
    groupId: {
      type: String,
      required: false,
      default: "",
    },
    subtitle: {
      type: String,
      required: false,
      default: "",
    },
    datas: {
      type: Array, //参照渡し(Object/Array)
      required: true,
      default: () => [],
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    handleAdd: {
      type: Function,
      required: true,
    },
    handleEdit: {
      type: Function,
      required: true,
    },
    handleDel: {
      type: Function,
      required: false,
    },
    handleOnSortMove: {
      type: Function,
      required: false,
    },
    handleOnSortEnd: {
      type: Function,
      required: false,
    },
  },
  data: () => ({
    headers: [
      {
        text: "並べ替え",
        value: "",
        class: "width10",
      },
      {
        text: "表示順",
        value: "order",
        class: "width10",
      },
      {
        text: "",
        value: "image",
        class: "width10",
      },
      {
        text: "名前",
        value: "name",
        class: "width10",
      },
      {
        text: "金額",
        value: "amount",
        class: "width10",
      },
      {
        text: "税区分",
        value: "tax",
        class: "width10",
      },
      {
        text: "割引",
        value: "discount",
        class: "width10",
      },
      {
        text: "提供状態",
        value: "provideStatusId",
        class: "width10",
      },
      {
        text: "公開状態",
        value: "publish",
        class: "width10",
      },
      {
        text: "編集",
        value: "edit",
        class: "width10",
      },
      // {
      //   text: "削除",
      //   value: "delete",
      // },
    ],
    tmpItems: [],
    delInfo: { show: false, index: -1 },
  }),
  watch: {
    datas: {
      handler: function (newValue) {
        this.tmpItems = _cloneDeep(newValue);
      },
      deep: true,
      immediate: true, // 初回読込時も動作させる
    },
  },
  computed: {
    ...mapGetters("imageUpload", ["noImage"]),
  },
  methods: {
    createImageUrl: function (menu) {
      const imageUrl = menu.image_url;
      return imageUrl ? imageUrl : this.noImage.url;
    },
    createRowStyle(element) {
      // 「公開中 or 提供可能」以外はグレーアウト
      if (!element.publish || element.provideStatus !== 0) {
        return "grey lighten-4";
      }
      return "";
    },
    createDiscountStatusText: function (discountStatus) {
      discountStatus = discountStatus ?? 0; // default
      return DISCOUNT_STATUS.find((ds) => ds.id === discountStatus)?.name ?? "";
    },
    createDiscountStatusStyle: function (discountStatus) {
      discountStatus = discountStatus ?? 0; // default
      return discountStatus !== 0 ? "red--text" : "";
    },
    createProvideStatusText: function (provideStatusId) {
      return PROVIDE_STATUS.find((ps) => ps.id === provideStatusId)?.name ?? "";
    },
    createProvideStatusStyle: function (provideStatusId) {
      return provideStatusId === 0 ? "red--text" : "";
    },
    // 追加 押下
    add: function (groupId) {
      this.handleAdd(groupId);
    },
    // 編集 押下
    edit(element) {
      this.handleEdit(element);
    },
    // 削除 押下
    del(id) {
      // 確認ダイアログ
      console.log(`del: id=${id}`);
      this.delInfo = { show: true, id: id };
    },
    confirmDel(delInfo) {
      console.log(`confirmDel: ${JSON.stringify(delInfo)}`);
      const targetId = delInfo.id;
      if (!targetId) {
        throw new Error(`invalid targetId: ${targetId}`);
      }
      this.delInfo = { show: false };
      this.handleDel(delInfo);
    },
    // ドラッグイベント
    onmove(updatedItems) {
      // console.log(`onmove: ${JSON.stringify(updatedItems)}`);
      if (!this.sortable) {
        return; // 何もしない
      }
      if (this.handleOnSortMove) {
        this.handleOnSortMove({
          groupId: this.groupId,
          items: updatedItems,
        });
      }
    },
    onend(updatedItems) {
      // console.log(`onend: ${JSON.stringify(updatedItems)}`);
      if (!this.sortable) {
        return; // 何もしない
      }
      if (this.handleOnSortEnd) {
        this.handleOnSortEnd({
          groupId: this.groupId,
          items: updatedItems,
        });
      }
    },
  },
};
</script>
<style>
@import "../../../../css/sortableList.css";
</style>
